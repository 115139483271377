<template>
  <div>
    <registries-main></registries-main>
  </div>
</template>

<script>
import RegistriesMain from "@/components/admin/registries/registriesMain";
export default {
  name: "registriesMainView",
  components: { RegistriesMain },
};
</script>

<style scoped></style>
