<template>
  <div>
    <h2>Список реестров</h2>

    <el-divider></el-divider>
    <el-row :gutter="10">
      <el-col :span="20">
        <p>Реестр объектов по использованию отходов</p>
      </el-col>
      <el-col :span="4">
        <el-button @click="$router.push('use_registry')" type="warning"
          >Просмотр</el-button
        >
      </el-col>
    </el-row>

    <el-divider></el-divider>

    <el-row :gutter="10">
      <el-col :span="20">
        <p>Реестр объектов обезвреживания отходов</p>
      </el-col>
      <el-col :span="4">
        <el-button type="warning">Просмотр</el-button>
      </el-col>
    </el-row>

    <el-divider></el-divider>

    <el-row :gutter="10">
      <el-col :span="20">
        <p>Реестр объектов хранения и захоронения отходов</p>
      </el-col>
      <el-col :span="4">
        <el-button type="warning">Просмотр</el-button>
      </el-col>
    </el-row>

    <el-divider></el-divider>
  </div>
</template>

<script>
export default {
  name: "registriesMain",
};
</script>

<style scoped></style>
